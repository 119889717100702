<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <div v-if="pageLoading" class="page__loading">
          <LoadingIndicator title="Загрузка" />
        </div>
        <section v-else-if="department" class="section section-pb48 contacts__section">
          <h2>Контактная информация</h2>
          <div class="contacts__block">
            <h3>Как нас найти:</h3>
            <div class="contacts__container">
<!--              <span v-if="mapError" class="contacts__map-error">Ошибка</span>-->
              <LoadingIndicator v-if="!mapReady && !mapError" title="Загрузка" />
              <yandex-map v-else-if="mapReady && !mapError" :settings="settings" :coords="coords" zoom="16">
                <ymap-marker marker-id="0" :coords="coords" :icon="icon" />
              </yandex-map>
              <div class="contacts__list">
                <div v-if="department.address" class="contacts__item">
                  <div class="contacts__item-title">Адрес:</div>
                  <div class="contacts__item-text">{{ department.address }}</div>
                </div>
                <div v-if="department.department_info && department.department_info.phone" class="contacts__item">
                  <div class="contacts__item-title">Телефоны:</div>
                  <div class="contacts__item-text">{{ department.department_info.phone }}</div>
                </div>
                <div v-if="department.url" class="contacts__item">
                  <div class="contacts__item-title">Официальный сайт:</div>
                  <a :href="department.url" target="_blank" class="contacts__item-text">{{ department.url }}</a>
                </div>
                <div v-if="department.department_info && department.department_info.email" class="contacts__item">
                  <div class="contacts__item-title">Электронная почта:</div>
                  <div class="contacts__item-text">{{ department.department_info.email }}</div>
                </div>
                <div v-if="hasText(department.schedule)" class="contacts__item">
                  <div class="contacts__item-title">Режим работы:</div>
                  <EditorJSComponent
                    :text="JSON.parse(department.schedule)"
                    v-if="isJson(department.schedule) && JSON.parse(department.schedule)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="contacts__reception-block">
            <h3>Обращение граждан и юридических лиц</h3>
            <p class="contacts__text">
              Обращение в адрес управления можно направить в электронном виде через «Электронную
              приёмную» департамента, подать лично или представителем по доверенности на бумажном
              носителе по адресу: 387000, г. Махачкала, пл. Ленина, дом 2. или направить почтовым
              отправлением по вышеуказанному адресу.</p>
            <router-link :to="{ name: 'appeal' }" class="btn-transpar_blue_dark btn--small">
              Электронная приёмная
            </router-link>
          </div>
        </section>
        <p v-else class="_item__title">Информация отсутствует</p>
      </div>
    </div>
  </main>
</template>

<script>
import EditorJSComponent from 'components/EditorJSComponent.vue'
import { loadYmap, yandexMap, ymapMarker } from 'vue-yandex-maps'
import LoadingIndicator from 'components/LoadingIndicator.vue'
import THIS_DEPARTMENT from '@/graphql/department/thisDepartment.graphql'

export default {
  name: 'ContactsPage',
  // async asyncData ({ store, apollo }) {
  //   await apollo.clients.dept.query({
  //     query: THIS_DEPARTMENT
  //   }).then(({ data }) => {
  //     if (data.this_department) {
  //       store.this_department = data.this_department
  //     }
  //   })
  // },
  data () {
    return {
      pageLoading: true,
      coords: [0, 0],
      settings: {
        apiKey: '4f77a55a-eec1-4f77-961f-42cd4e50b13c',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      },
      icon: {
        layout: 'default#imageWithContent',
        imageHref: '/static/images/map-red-pin.svg',
        imageSize: [36, 36],
        imageOffset: [-18, -18]
      },
      mapReady: false,
      mapError: false
    }
  },
  created () {
    this.$apollo.provider.clients.dept.query({
      query: THIS_DEPARTMENT
    })
      .then(({ data }) => {
        this.pageLoading = false
        if (data.this_department) {
          this.$store.state.this_department = data.this_department
        }
      })
      .catch((err) => {
        this.pageLoading = false
        console.log(err)
      })
  },
  mounted () {
    loadYmap({
      ...this.settings
    }).then(() => {
      window.ymaps.ready(() => {
        window.ymaps.geocode(this.department.address)
          .then((res) => {
            this.coords = res.geoObjects.get(0).geometry.getCoordinates()
            this.mapReady = true
          })
          .catch(() => {
            this.mapError = true
          })
      })
    })
  },
  computed: {
    department () {
      return this.$store.state.this_department
    }
  },
  methods: {
    hasText (text) {
      if (this.isJson(text)) {
        const parsedText = JSON.parse(text)
        return !!(parsedText && parsedText.blocks && parsedText.blocks.length)
      }
      return !!text
    },
    isJson (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Официальный сайт Главы города Махачкалы',
      '',
      '',
      'Официальный сайт Главы города Махачкалы',
      '',
      ''
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    LoadingIndicator,
    EditorJSComponent,
    yandexMap,
    ymapMarker
  }
}
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"

.contacts__section {
  .ymap-container {
    width 100%
    height 414px
    +below(420px) {
      height 350px
    }
  }

  .contacts {
    &__block {
      margin-bottom: 32px;
      +below(768px) {
        margin-bottom: 24px;
      }
    }

    &__container {
      display grid
      grid-template-columns 460px auto
      align-items: flex-start;
      gap 32px
      +below(880px) {
        display flex
        flex-direction column-reverse
        gap 16px
      }
    }

    &__list {
      display flex
      flex-direction column
      gap 24px
      +below(768px) {
        gap 16px
      }
    }

    &__item {
      display flex
      flex-direction column
      gap 6px

      &-title {
        color var(--color_gray_dark)
        +below(380px) {
          font-size 0.875em
          line-height 20px
        }
      }

      &-text {
        word-break break-word
        +below(380px) {
          font-size 0.875em
          line-height 20px
        }
      }
    }

    &__map-error {
      width 100%
      height 414px // высота .ymap-container
      border: 1px solid var(--color_gray)
      padding 10px
      +below(420px) {
        height 350px
      }
    }

    &__text {
      +below(380px) {
        font-size 0.875em
        line-height 20px
      }
    }
  }
}

</style>
